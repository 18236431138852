import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from 'components/App';
import 'antd/dist/reset.css';

import 'index.css';
import 'styles/global.scss';

const root = createRoot(document.getElementById('root'));

root.render(
  <>
    <App />
  </>
);
